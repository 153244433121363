<template>
    <!-- 轮播图组件 -->
    <div class="banner-carousel">
        <el-carousel 
            trigger="click" 
            height="500px"
            class="banner-carousel-box">
            <el-carousel-item v-for="(item, index) in banners" :key="index">
                <div class="banner-carousel-img" v-if="item.img">
                    <img :src="item.img" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: mapState({
        banners: state => state.banner.banners,
    }),
}
</script>

<style scoped>
.banner-carousel{
    position: relative;
    width: 100%;
    overflow: hidden;
}
.banner-carousel-box >>> .el-carousel__button{
    background-color: #8d9afc;
}
.banner-carousel-img{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #ddd
}
.banner-carousel-img>img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-height: 100%;
}
</style>
